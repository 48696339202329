<template>
  <b-card
    :title=title
    :img-src="imgSrc"
    img-top
    class="avify-card">
    <b-card-body>
      <slot name="card-body"></slot>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: String,
    imgSrc: String
  }
}
</script>

<style lang="less">
  @import 'card.less';
</style>
