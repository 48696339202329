<template>
  <public-template>
    <template v-slot:public-template>
      <b-row class="flex-column flex-sm-row flex-lg-row" align-h="center">
        <b-col col xs="12" sm="8" lg="4">
          <card>
            <template v-slot:card-body>
              <div v-if="validationOK">
                <div class="confirmation-valitaded-email" v-if="emailValidated">
                  <img src="../assets/icons/icon-confirmation-email.svg" class="mb-4">
                  <h5>Tu email ha sido confirmado satisfactoriamente</h5>
                  <b-link :to="{ name: 'login'}" class="btn btn-primary mt-4">Continuar</b-link>
                </div>
                <div class="confirmation-valitaded-email" v-else>
                  <img src="../assets/icons/icon-confirmation-email.svg" class="mb-4">
                  <h5>Estamos confirmando tu email</h5>
                </div>
              </div>
              <div v-else>
                <div class="confirmation-error-email">
                  <img src="../assets/icons/icon-confirmation-email.svg" class="mb-4">
                  <h5>No se ha podido confirmar tu email</h5>
                  <b-link :to="{ name: 'login'}" class="btn btn-primary mt-4">Continuar</b-link>
                </div>
              </div>
            </template>
          </card>
        </b-col>
      </b-row>
    </template>
  </public-template>
</template>

<script>
import Card from '../components/Card/Card'
export default {
  name: 'EmailValidation',
  components: { Card },
  data () {
    return {
      emailValidation: {
        token: null
      },
      emailValidated: false,
      validationOK: true
    }
  },
  created () {
    this.emailValidation.token = this.$route.params.emailValidationToken
  },
  mounted () {
    this.$store.dispatch('emailValidation', this.emailValidation)
      .then(() => {
        this.emailValidated = true
      })
      .catch(error => {
        console.log(error)
        this.validationOK = false
      })
  }
}
</script>
